import React from "react";
import { Link, withRouter } from "react-router-dom";

const Navbar = props => {
  let path = props.location.pathname;
  let isSelected = {
    home: path === "/",
    about: path === "/about",
    quote: path === "/quote",
    tracking: path === "/tracking",
    contact: path === "/contact"
  };
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">
        <img
          src={require("../res/img/logo-square.png")}
          alt="Prototronics Logo"
          style={{ height: "38px", marginRight: '12px' }}
        />
        Prototronics
      </a>
      <button
        type="button"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarContent"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div id="navbarContent" className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto">
          <NavbarItem isSelected={isSelected.home} path="/" text="Home" />
          <NavbarItem
            isSelected={isSelected.about}
            path="/about"
            text="About"
          />
          <NavbarItem
            isSelected={isSelected.quote}
            path="/quote"
            text="Get Quote"
          />
          <NavbarItem
            isSelected={isSelected.contact}
            path="/contact"
            text="Contact"
          />
        </ul>
      </div>
    </nav>
  );
};

const NavbarItem = props => {
  return (
    <li className={props.isSelected ? "nav-item active" : "nav-item"}>
      <Link className="nav-link" to={props.path}>
        {props.text}
      </Link>
    </li>
  );
};

export default withRouter(Navbar);
