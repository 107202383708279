import React from "react";

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.config = {
      pcbsPerPanel: 3,
      panelsInOven: 3,
      panelsInWasher: 3,
      hourlyCostManual: 25,
      hourlyCostRobot: 25
    };
    this.manualOperations = {
      stencilSetup: {
        minutes: 30,
        job: true,
        side: true
      },
      paste: {
        minutes: 1 / this.config.pcbsPerPanel,
        pcb: true,
        side: true
      },
      place: {
        minutes: 0.3,
        comp: true
      },
      reflow: {
        minutes: 15 / this.config.pcbsPerPanel / this.config.panelsInOven,
        pcb: true,
        side: true
      },
      inspect: {
        minutes: 2.6 / 60,
        comp: true
      },
      wash: {
        minutes:
          (15 * 2) / this.config.pcbsPerPanel / this.config.panelsInWasher,
        pcb: true
      },
      depanel: {
        minutes: 0.5,
        pcb: true
      },
      th: {
        minutes: 1.5
      }
    };
    this.state = {
      pcba: 0,
      sides: 1,
      parts: 0,
      smt: 0,
      th: 0,
      cost: 0
    };
  }
  computeCost = (pcba, sides, parts, smt, th) => {
    let smtTotal = pcba * smt;
    let thTotal = pcba * th;
    let manualOperationsMinutes = {
      stencilSetup: this.computeStage(
        this.manualOperations.stencilSetup,
        pcba,
        sides,
        smtTotal
      ),
      paste: this.computeStage(
        this.manualOperations.paste,
        pcba,
        sides,
        smtTotal
      ),
      place: this.computeStage(
        this.manualOperations.place,
        pcba,
        sides,
        smtTotal
      ),
      reflow: this.computeStage(
        this.manualOperations.reflow,
        pcba,
        sides,
        smtTotal
      ),
      inspect: this.computeStage(
        this.manualOperations.inspect,
        pcba,
        sides,
        smtTotal
      ),
      wash: this.computeStage(
        this.manualOperations.wash,
        pcba,
        sides,
        smtTotal
      ),
      depanel: this.computeStage(
        this.manualOperations.depanel,
        pcba,
        sides,
        smtTotal
      ),
      th: this.manualOperations.th.minutes * th * pcba || 0
    };
    let manualOperationsMinutesTotal =
      manualOperationsMinutes.stencilSetup +
      manualOperationsMinutes.paste +
      manualOperationsMinutes.place +
      manualOperationsMinutes.reflow +
      manualOperationsMinutes.inspect +
      manualOperationsMinutes.wash +
      manualOperationsMinutes.depanel +
      manualOperationsMinutes.th;
    let manualOperationsCost =
      (manualOperationsMinutesTotal / 60) * this.config.hourlyCostManual;
    return manualOperationsCost;
  };
  computeStage = (operation, pcba, sides, smtTotal) => {
    let multiplier = operation.side ? sides : 1;
    let sum =
      (operation.comp ? smtTotal : 0) +
      (operation.pcb ? pcba : 0) +
      (operation.job ? 1 : 0);
    return parseInt(operation.minutes * multiplier * sum);
  };
  updateResult = ev => {
    var { pcba, sides, parts, smt, th } = this.state;
    switch (ev.target.id) {
      case "pcba":
        pcba = this.intOrZero(ev.target.value);
        this.setState({
          pcba: pcba,
          cost: this.computeCost(pcba, sides, parts, smt, th)
        });
        break;
      case "bom":
        parts = this.intOrZero(ev.target.value);
        this.setState({
          parts: parts,
          cost: this.computeCost(pcba, sides, parts, smt, th)
        });
        break;
      case "smt":
        smt = this.intOrZero(ev.target.value);
        this.setState({
          smt: smt,
          cost: this.computeCost(pcba, sides, parts, smt, th)
        });
        break;
      case "th":
        th = this.intOrZero(ev.target.value);
        this.setState({
          th: th,
          cost: this.computeCost(pcba, sides, parts, smt, th)
        });
        break;
      case "one-side":
        this.setState({
          sides: 1,
          cost: this.computeCost(pcba, 1, parts, smt, th)
        });
        break;
      case "two-sides":
        this.setState({
          sides: 2,
          cost: this.computeCost(pcba, 2, parts, smt, th)
        });
        break;
    }
  };
  intOrZero = s => {
    return !isNaN(s) && !isNaN(parseFloat(s)) ? parseInt(s) : 0;
  };
  render() {
    return (
      <form>
        <div className="form-group">
          <label for="pcba">Estimated PCBA</label>
          <input
            id="pcba"
            className="form-control"
            type="number"
            placeholder="Quantity"
            onChange={this.updateResult}
          />
        </div>
        <fieldset className="form-group">
          <label for="sides">Number of sides</label>
          <div className="form-check">
            <input
              id="one-side"
              className="form-check-input"
              type="radio"
              name="one-side"
              checked={this.state.sides === 1}
              onChange={this.updateResult}
            />
            <label for="one-side">One side</label>
          </div>
          <div className="form-check">
            <input
              id="two-sides"
              className="form-check-input"
              type="radio"
              name="two-sides"
              checked={this.state.sides === 2}
              onChange={this.updateResult}
            />
            <label for="two-sides">Two sides</label>
          </div>
        </fieldset>
        <div className="form-group">
          <label for="bom">Number of unique parts</label>
          <input
            id="bom"
            className="form-control"
            type="number"
            placeholder="Quantity"
            onChange={this.updateResult}
          />
        </div>
        <div className="form-group">
          <label for="smt">Number of SMT parts</label>
          <input
            id="smt"
            className="form-control"
            type="number"
            placeholder="Quantity on each board"
            onChange={this.updateResult}
          />
        </div>
        <div className="form-group">
          <label for="th">Number of through-hole parts</label>
          <input
            id="th"
            className="form-control"
            type="number"
            placeholder="Quantity on each board"
            onChange={this.updateResult}
          />
        </div>
        <div className="my-4">
          <p className="text-muted">Total assembly cost</p>
          <p className="lead text-dark font-weight-bold">
            €{parseFloat(this.state.cost).toFixed(2)}
          </p>
          <small className="text-muted">
            Notice: The price does not include PCB fabrication or the cost of
            the components. Exact quotation will be updated after all the files
            you uploaded are reviewed. For further information please contact
            us.
          </small>
        </div>
      </form>
    );
  }
}

export default Calculator;
