import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Calculator from "../components/Calculator";

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Quality PCB prototypes | Prototronics</title>
      </Helmet>
      <div id="page-content">
        <Navbar />
        <div className="jumbo-outer-container">
          <div className="container">
            <div className="jumbotron">
              <h1>Quick-turn PCB assembly</h1>
              <p>Quality prototype assembly services based in Eastern Europe</p>
              <hr className="my-4" />
              <p>Get an instant quote for your assembly needs</p>
              <Calculator />
              <br />
              <p>Want an exact quotation for your prototype?</p>
              <a href="/quote">
                <button className="btn btn-primary" type="button">
                  Request a quote
                </button>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Home;
