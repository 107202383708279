import React from "react";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-10 footer-column my-auto">
            <p className="footer-text">Copyright © 2020 Prototronics S.R.L.</p>
            <p className="footer-text">
              Start-up cofinanțat din Fondul Social European prin Programul
              Operațional Capital Uman 2014-2020
            </p>
          </div>
          <div className="col-sm-2 footer-column text-right">
            <a
              href="http://www.facebook.com/prototronics.eu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../res/img/social/facebook.png")}
                className="footer-social"
                alt="Facebook link icon"
              />
            </a>
            <a
              href="http://www.linkedin.com/company/prototronics"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../res/img/social/linkedin.png")}
                className="footer-social"
                alt="Linkedin link icon"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
