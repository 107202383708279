import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const About = props => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Quick-turn PCB assembly | Prototronics</title>
      </Helmet>
      <div id="page-content">
        <Navbar />
        <div className="container">
          <div className="row pt-5">
            <div className="col-sm-12 col-md-6 col-lg-7 col-xl-8">
              <span>ProtoTronics - One Stop Solutions</span>
              <h2>Take your product from idea to real world impact</h2>
              <p>
                ProtoTronics is providing complete solutions for PCB assembly
                projects, being highly specialized in quick-turn prototypes PCB
                assembly and small-volume production where speed and time to
                market are critical.
              </p>
              <p>
                We take care of your requirements from the quotation stage
                through component procurement, assembly, testing, packing and
                worldwide shipping.
              </p>
              <p>Our services include:</p>
              <ul>
                <li>Online quotations, ordering and tracking</li>
                <li>One to one professional consulting services</li>
                <li>Technical reviews</li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
              <img
                src={require("../res/img/blur-capacitors-chips-pexels.jpg")}
                className="about-image-top"
                alt=""
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <h2>A to Z services</h2>
              <p>We take care of your requirements through all stages</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 mb-4">
              <img
                src={require("../res/img/capacitor-chip-circuit-pexels.jpg")}
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-sm-12 col-md-6 mb-4">
              <h3>1. Component procurement</h3>
              <p>The possible options are:</p>
              <ol>
                <li>
                  <p>Customer offers all the parts</p>
                </li>
                <li>
                  <p>
                    Combo: Some parts are offered by the customer and
                    Prototronics is sourcing the rest
                  </p>
                </li>
                <li>
                  <p>
                    Turn-key: All parts are sourced by Prototronics. When
                    choosing this solution, the parts will be sourced from
                    Digikey, Mouser, TME, Element 14 or the local electronic
                    market
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <h3>2. Assembly</h3>
              <p>
                We’re often recognized for our high-quality manufacturing, a
                direct result of our commitment to excellence throughout the
                years. Day in and day out, we work hard to make sure our
                standards not only remain, but are continuously pushed higher.
              </p>
              <p>Our capabilities include:</p>
              <ul>
                <li>
                  <p>
                    Assembly on FR4 Rigid PCB, Aluminium PCB or Rigid-Flex PCB
                  </p>
                </li>
                <li>
                  <p>
                    SMT assembly (BGA, QFN, QFP, SOIC, PLCC, PoP, 0805, 0603,
                    0402), through-hole assembly, single and double sides
                    assembly, sub-assembly
                  </p>
                </li>
                <li>
                  <p>Soldering lead free (ROHS compliant)</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-7 mb-4">
              <h3>3. Quality control</h3>
              <p>
                All PCBs assembled by ProtoTronics are carefully monitored
                through a rigorous process by our quality engineers that assures
                accuracy using the latest automated equipment. Offering a
                comprehensive PCB Assembly solution to the highest standard of
                efficiency, flexibility and quality are our promise.
              </p>
              <p>Quality procedures include:</p>
              <ul>
                <li>
                  <p>IPC-A-610</p>
                </li>
                <li>
                  <p>Visual inspection</p>
                </li>
                <li>
                  <p>Functional testing</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-5 mb-4">
              <img
                src={require("../res/img/electronic-board-pixabay.jpg")}
                className="w-100"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <h3>4. Packing and worldwide shipping</h3>
              <p>
                All PCBAs will be packed with ESD bags/ESD foam to prevent
                possible static damage, and quality cardboard boxes will be used
                to protect your PCBAs during shipping. You can also inform us
                about any special packing requirements.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default About;
