import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const NotFound = props => {
  return (
    <React.Fragment>
      <Helmet>
        <title>404 Page Not Found | Prototronics</title>
      </Helmet>
      <div id="page-content">
        <Navbar />
        <div className="container mt-5">
          <h2>404 - Page not found</h2>
          <p>This link has either expired or is broken</p>
          <a href="/">
            <button className="btn btn-primary" type="button">
              Go back home
            </button>
          </a>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default NotFound;
