import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from '../containers/Home';
import About from '../containers/About';
import Quote from '../containers/Quote';
import Tracking from '../containers/Tracking';
import Contact from '../containers/Contact';
import NotFound from '../containers/404';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/about' component={About}/>
        <Route path='/quote' component={Quote}/>
        <Route path='/tracking' component={Tracking}/>
        <Route path='/contact' component={Contact}/>
        <Route component={NotFound}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
