import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Tracking = props => {
  let mailBody =
    "1.PCB files:\n\n2.Project BOM:\n\n3.Quantity and PCB specification:\n\n4.Parts mapping:\n\n5.Coordinate file for pick and place\n\n6.Other specific requirements:\n\n7.Testing plan\n\n8.Shipping address and method";
  let mailHref = `mailto:sales@prototronics.eu?subject=New quote request&body=${encodeURIComponent(
    mailBody
  )}`;
  return (
    <React.Fragment>
      <Helmet>
        <title>PCB assembly estimation | Prototronics</title>
      </Helmet>
      <div id="page-content">
        <Navbar />
        <div className="container pt-5">
          <h1>Request a quote</h1>
          <p>
            To get an accurate quotation for your PCB assembly project, please
            send the following information to sales@prototronics.eu
          </p>
          <ol>
            <li>
              <p>PCB Gerber files</p>
            </li>
            <li>
              <p>BOM of your project</p>
            </li>
            <li>
              <p>Quantity and PCB specification</p>
            </li>
            <li>
              <p>
                Parts mapping for soldering with parts machine, or a simple
                mapping for hand soldering
              </p>
            </li>
            <li>
              <p>Coordinate file for pick and place machine</p>
            </li>
            <li>
              <p>
                Your specific requirements, or some tips you want to share with
                us to avoid mistakes
              </p>
            </li>
            <li>
              <p>Testing plan</p>
            </li>
            <li>
              <p>
                Your shipping address and shipping method of choice
                (DHL/FedEx/UPS/etc)
              </p>
            </li>
          </ol>
          <a href={mailHref}>
            <button className="btn btn-primary" type="button">
              Write email
            </button>
          </a>
          <img
            src={require("../res/img/circuit-board-pixabay.jpg")}
            alt=""
            className="my-4 w-100"
            style={{ height: "350px", objectFit: 'cover' }}
          />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Tracking;
