import React from "react";
import { Helmet } from "react-helmet";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MAPBOX_KEY } from "../Config";

const Contact = props => {
  let ICON_PHONE = require("../res/img/ic_phone.png");
  let ICON_PIN = require("../res/img/ic_pin.png");
  let ICON_MAIL = require("../res/img/ic_mail.png");

  let centerLocation = { lat: 47.79673, lon: 22.877833 };
  let initialZoom = [12];
  let businessLocation = [22.877671, 47.806720];

  const Map = ReactMapboxGl({
    accessToken: MAPBOX_KEY
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>PCB prototyping services in Romania | Prototronics</title>
        <script src="https://api.mapbox.com/mapbox.js/v3.2.0/mapbox.js"></script>
        <link
          href="https://api.mapbox.com/mapbox.js/v3.2.0/mapbox.css"
          rel="stylesheet"
        />
      </Helmet>
      <div id="page-content">
        <Navbar />
        <div className="container-fluid contact-container">
          <div className="row">
            <div className="col-sm-12 col-md-6 contact-content">
              <h1 className="text-center">Prototronics SRL</h1>
              <img src={ICON_MAIL} alt="Mail icon" />
              <p>sales@prototronics.eu</p>
              <img src={ICON_PHONE} className="d-none" alt="Phone icon" />
              <p className="d-none">+40 777 000 000</p>
              <img src={ICON_PIN} alt="Pin icon" />
              <p>25 Gheorghe Barițiu st.</p>
              <p>440068 Satu Mare, Romania</p>
            </div>
            <div className="col-sm-12 col-md-6 p-0">
              <Map
                className="contact-map"
                style="mapbox://styles/mapbox/light-v10"
                center={centerLocation}
                zoom={initialZoom}
              >
                <Marker coordinates={businessLocation}>
                  <img
                    className="contact-map-pin"
                    src={ICON_PIN}
                    alt="Pin icon on the map"
                  />
                </Marker>
              </Map>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Contact;
