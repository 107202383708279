import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Tracking = props => {
  return (
    <React.Fragment>
      <Helmet>
        <title>PCB assembly order tracking | Prototronics</title>
      </Helmet>
      <div id="page-content">
        <Navbar />
        <div className="container mt-4">
          <h1>Order tracking</h1>
          <p>
            You can track your PCB and assembly order status online. You will be
            updated on the PCB manufacturing process and the shipping status of
            your order regularly.
          </p>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Tracking;
